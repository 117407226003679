<script lang="ts" setup>
import type { AccordionItem } from "#ui/types";
import type { LinkItem } from "~~/models/links";
import type { TechnicalDetailsSectionRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage } from "vue-datocms";

const { data } = defineProps<{ data: TechnicalDetailsSectionRecord }>();

const localePath = useLocalePath();

const technicalDetails = computed<Array<AccordionItem>>(() => {
  return data.technicalDetails.map((technicalDetail) => {
    return {
      label: technicalDetail.title,
      content: technicalDetail.info,
    } satisfies AccordionItem;
  });
});

const downloadLink = computed<LinkItem>(() => {
  return getLinkForType(data.downloadLink, localePath);
});
</script>

<template>
  <div class="bg-primary py-[56px] text-white" :class="{ 'lg:pt-3xl': data.sectionTitle }">
    <UContainer>
      <SectionTitle as="span" :label="data.sectionTitle" />
      <div class="gap-xl flex flex-col md:flex-row md:gap-[72px]">
        <div class="pt-md flex-1 md:order-1">
          <h3 class="mb-4xl uppercase text-inherit" v-html="data.title" />
          <Accordion :items="technicalDetails" />
          <UButton :to="downloadLink.href!" color="white" variant="ghost" icon="i-ph-download-simple" class="border-b-gradient-white">
            {{ downloadLink.label }}
          </UButton>
        </div>
        <div class="md:py-2xl flex flex-1 items-center">
          <DatocmsImage v-if="data.image" :data="data.image.responsiveImage" class="h-full" object-fit="cover" />
        </div>
      </div>
    </UContainer>
  </div>
</template>
