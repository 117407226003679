<script lang="ts" setup>
import type { TitleAndTextSectionRecord } from "~~/server/graphql/datocms/generated";

const { data } = defineProps<{ data: TitleAndTextSectionRecord }>();

const colorMode = computed(() => {
  return data.colorMode ? "light" : "dark";
});
</script>

<template>
  <div :data-variant="colorMode" class="bg-[--color-bg] text-[--color-text]" :class="{ 'pt-4xl pb-5xl': data.extraMargin }">
    <UContainer class="pt-2xl md:pt-6xl pb-0">
      <SectionTitle as="h2" :label="data.sectionTitle" class="md:-mt-2xl mb-lg !block" :color="colorMode === 'light' ? 'black' : 'white' " />
      <TitleAndTextBlock :data="data.titleAndText" :color-mode="colorMode" />
    </UContainer>
  </div>
</template>

<style scoped>
:root {
  .sf-hangcha {
    [data-variant="light"] {
      --color-bg: rgb(var(--color-white));
      --color-text: rgb(var(--color-black));
    }
    [data-variant="dark"] {
      --color-bg: rgb(var(--color-black));
      --color-text: rgb(var(--color-white));
    }
  }
}
</style>
