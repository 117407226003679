<script lang="ts" setup>
import type { LinkItem } from "~~/models/links";
import type { TitleAndTextRecord } from "~~/server/graphql/datocms/generated";

const { data, colorMode = "light" } = defineProps<{
  data: TitleAndTextRecord;
  colorMode?: "light" | "dark";
}>();

const localePath = useLocalePath();

const link = computed<LinkItem | undefined>(() => {
  return data.link ? getLinkForType(data.link, localePath) : undefined;
});
</script>

<template>
  <div :data-variant="colorMode" class="max-w-[650px]">
    <h3
      class="mb-xl leading-p-150 text-2xl uppercase text-inherit md:text-3xl [&>p]:mb-0 [&_em]:font-bold [&_em]:text-[--color-accent]"
      v-html="data.title"
    />

    <div class="[&>*]:text-inherit [&>p:last-child]:mb-0" v-html="data.text" />

    <div v-if="link" class="mt-lg md:mt-xl">
      <UButton v-if="data.linkVariant" :to="link.href!" color="black" icon="i-ph-arrow-right" trailing>
        {{ link.label }}
      </UButton>

      <UButton
        v-if="!data.linkVariant" :to="link.href!" color="black" variant="ghost" class="border-b-gradient-black" icon="i-ph-arrow-right" trailing
      >
        {{ link.label }}
      </UButton>
    </div>
  </div>
</template>

<style scoped>
:root {
  .sf-hangcha {
    [data-variant="light"] {
      --color-bg: rgb(var(--color-white));
      --color-text: rgb(var(--color-black));
      --color-accent: rgb(var(--color-light-accent));
    }
    [data-variant="dark"] {
      --color-bg: rgb(var(--color-black));
      --color-text: rgb(var(--color-white));
      --color-accent: rgb(var(--color-dark-accent));
    }
  }
}
</style>
