<script lang="ts" setup>
import type { DealersSectionRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";

defineProps<{ data: DealersSectionRecord }>();

const localePath = useLocalePath();
</script>

<template>
  <UContainer>
    <SectionTitle as="h2" :label="data.sectionTitle" seo-fallback-label="Dealers" color="black" class="!my-lg" />

    <div v-if="data.mapImage">
      <DatocmsImage
        :data="data.mapImage.responsiveImage as ResponsiveImageType"
        object-fit="cover"
        object-position="center"
      />
    </div>

    <div class="py-4xl gap-3xl md:gap-5xl flex flex-wrap justify-center lg:gap-[120px]">
      <div v-for="dealer in data.dealers" :key="dealer.id" class="flex w-[250px] flex-col items-center">
        <h3 class="h5 font-bold">
          <span v-if="!dealer.optLinkItem">{{ dealer.name }}</span>

          <NuxtLink v-if="dealer.optLinkItem" :to="getLinkForType(dealer.optLinkItem, localePath).href ?? '/'">
            {{ dealer.name }}
          </NuxtLink>
        </h3>

        <div class="gap-xs flex flex-col items-center">
          <span class="text-center font-bold md:text-lg">{{ dealer.address }}</span>
          <span class="gap-2xs flex items-center">
            <UIcon name="i-ph-phone" />
            <a :href="`tel:${dealer.phoneNumber}`">{{ dealer.phoneNumber }}</a>
          </span>
          <span class="gap-2xs flex items-center">
            <UIcon name="i-ph-envelope-simple" />
            <a :href="`mailto:${dealer.eMailAddress}`">{{ dealer.eMailAddress }}</a>
          </span>
        </div>
      </div>
    </div>
  </UContainer>
</template>
