<script lang="ts" setup>
// looping over sections, using dynamic components, in a typed way
import {
  SectionsBannerCarousel,
  SectionsCallToAction,
  SectionsCatalog,
  SectionsCenteredText,
  SectionsContactTeasers,
  SectionsDealers,
  SectionsDivider,
  SectionsFallback,
  SectionsIconFeatures,
  SectionsImageFeatures,
  SectionsSeries,
  SectionsTeaserCarousel,
  SectionsTechnicalDetails,
  SectionsTextAndFullImage,
  SectionsTextAndImage,
  SectionsThreeImages,
  SectionsTitle,
  SectionsTitleAndText,
  SectionsUspList,
  SectionsUspScroll,
  SectionsVideo,
} from "#components";

defineProps<{ sections: any; currentPageId: string }>();

function GetComponentName(typename: string) {
  switch (typename) {
    case "BannerCarouselSectionRecord":
      return SectionsBannerCarousel;
    case "CallToActionSectionRecord":
      return SectionsCallToAction;
    case "CatalogSectionRecord":
      return SectionsCatalog;
    case "CenteredTextSectionRecord":
      return SectionsCenteredText;
    case "ContactTeasersSectionRecord":
      return SectionsContactTeasers;
    case "DealersSectionRecord":
      return SectionsDealers;
    case "SectionDividerRecord":
      return SectionsDivider;
    case "IconFeaturesSectionRecord":
      return SectionsIconFeatures;
    case "ImageFeaturesSectionRecord":
      return SectionsImageFeatures;
    case "SeriesSectionRecord":
      return SectionsSeries;
    case "TeaserCarouselSectionRecord":
      return SectionsTeaserCarousel;
    case "TechnicalDetailsSectionRecord":
      return SectionsTechnicalDetails;
    case "TextAndImageSectionRecord":
      return SectionsTextAndImage;
    case "TextAndFullImageSectionRecord":
      return SectionsTextAndFullImage;
    case "ThreeImagesSectionRecord":
      return SectionsThreeImages;
    case "TitleSectionRecord":
      return SectionsTitle;
    case "TitleAndTextSectionRecord":
      return SectionsTitleAndText;
    case "UspListSectionRecord":
      return SectionsUspList;
    case "UspScrollSectionRecord":
      return SectionsUspScroll;
    case "VideoSectionRecord":
      return SectionsVideo;
    default:
      return SectionsFallback;
  }
}
</script>

<template>
  <section v-for="section in sections" :key="section.id" :data-typename="section.__typename">
    <component :is="GetComponentName(section.__typename)" :data="section" :current-page-id="currentPageId" />
  </section>
</template>

<style scoped>

</style>
