<script lang="ts" setup>
// https://github.com/datocms/vue-datocms/tree/master/src/components/VideoPlayer
import type { VideoSectionRecord } from "~~/server/graphql/datocms/generated";
import { VideoPlayer } from "vue-datocms";

const { data } = defineProps<{ data: VideoSectionRecord }>();

// why muted? https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#autoplay
const autoplay = computed(() => data.autoplay ? "muted" : "false");
</script>

<template>
  <VideoPlayer v-if="data.file" :data="data.file.video" :autoplay="autoplay" primary-color="#D9D9D9" accent-color="#203259" />
</template>

<style scoped>

</style>
