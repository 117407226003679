<script lang="ts" setup>
import type { LinkItem } from "~~/models/links";
import type { CallToActionSectionRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";

const { data } = defineProps<{ data: CallToActionSectionRecord }>();

const localePath = useLocalePath();

const callToActionLink = computed<LinkItem>(() => {
  return getLinkForType(data.callToAction.actionLink, localePath);
});
</script>

<template>
  <UContainer class="max-lg:!px-0">
    <div class="lg:p-sm p-md gap-xl max-lg:px-lg flex flex-col justify-between bg-black text-white lg:flex-row">
      <div class="px-0 lg:max-w-[433px] lg:px-[36px] lg:py-[54px]" :class="{ 'pt-2xl lg:pt-xl': data.sectionTitle }">
        <SectionTitle as="h2" :label="data.sectionTitle" class="mb-lg" />
        <StructuredContent :data="data.callToAction.content" class="mt-md lg:mt-0 [&_*]:text-inherit [&_em]:text-[--color-content-text-accent]" />
        <UButton
          :to="callToActionLink.href!"
          :target="getTarget(callToActionLink.href!)"
          color="white" variant="ghost" icon="i-ph-arrow-right" trailing class="border-b-gradient-white"
        >
          {{ data.callToAction.actionLink.text }}
        </UButton>
      </div>
      <DatocmsImage
        v-if="data.callToAction.image"
        :data="data.callToAction.image.responsiveImage as ResponsiveImageType"
        class="flex-1"
        object-fit="cover"
        object-position="center"
      />
    </div>
  </UContainer>
</template>

<style>
:root {
  .sf-hangcha {
    --color-content-text-accent: rgb(var(--color-primary-300));
  }
}
</style>
