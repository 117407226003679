<script lang="ts" setup>
import type { SeriesSectionRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";

const { data } = defineProps<{ data: SeriesSectionRecord }>();

const localePath = useLocalePath();
</script>

<template>
  <UContainer class="py-3xl gap-4xl series-section flex flex-col">
    <div class="gap-xl flex flex-col">
      <div class="flex flex-wrap items-center justify-start md:justify-between">
        <span class="text-3xl uppercase [&>p:last-child]:mb-0" v-html="data.title" />
        <UButton
          v-if="data.showAllLink"
          :to="data.showAllLink.optLinkUrl ?? '/'"
          color="black" variant="outline" icon="i-ph-arrow-right" trailing class="max-w-full"
        >
          {{ data.showAllLink.text }}
        </UButton>
      </div>

      <hr class="border-t-gradient-black">
    </div>

    <div class="gap-3xl md:gap-4xl flex flex-wrap justify-center">
      <div
        v-for="serie of data.series"
        :key="serie.id"
        class="flex w-[325px] flex-col gap-[20px]"
      >
        <DatocmsImage
          :data="serie.teaserImage.responsiveImage as ResponsiveImageType"
          class="h-[376px]"
          object-fit="cover"
          object-position="center"
        />

        <div class="gap-lg flex flex-col items-start">
          <div class="text-lg [&>p:last-child]:mb-0" v-html="serie.teaserTitle" />
          <div v-if="serie.teaserText" class="gap-lg flex flex-col [&>p]:mb-0" v-html="serie.teaserText" />
          <UButton color="black" size="sm" :to="localePath({ name: 'series-slug', params: { slug: serie.slug } })">
            {{ serie.teaserLinkText }}
          </UButton>
        </div>
      </div>
    </div>
  </UContainer>
</template>

<style>
.series-section ul {
  list-style-image: url(/icons/check-circle-solid-black.png);
  list-style-position: inside;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
