<script lang="ts" setup>
import type { BannerCarouselSectionRecord, ImageFileField } from "~~/server/graphql/datocms/generated";

defineProps<{ data: BannerCarouselSectionRecord; currentPageId: string }>();

useSwiper();
</script>

<template>
  <div class="bg-black">
    <div class="relative">
      <SectionTitle as="h2" :label="data.sectionTitle" class="top-xl left-xl absolute z-10 text-black" />

      <ClientOnly>
        <SwiperCarousel v-slot="{ item }: { item: ImageFileField }" :items="data.banners" :navigation="true">
          <img
            :src="item?.responsiveImage.src"
            :alt="item?.alt ?? ''"
            :title="item?.title ?? ''"
            class="max-h-[700px] w-full object-cover md:max-h-[600px]"
            loading="lazy"
          >
        </SwiperCarousel>
      </ClientOnly>
    </div>
  </div>
</template>
