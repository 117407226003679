<script lang="ts" setup>
// https://vueuse.org/core/useElementVisibility/#directive-usage
import { vElementVisibility } from "@vueuse/components";

const target = useTemplateRef<HTMLElement>("target");
const isVisible = ref(false);

function onElementVisibility(state: boolean) {
  // use false check to prevent re-triggering the animation
  if (isVisible.value === false) {
    isVisible.value = state;
  }
}
</script>

<template>
  <div ref="target" class="fade-in-section" :class="{ 'is-visible': isVisible }">
    <div v-element-visibility="[onElementVisibility, { scrollTarget: target }]">
      <slot />
    </div>
  </div>
</template>

<style scoped>

</style>
