<script lang="ts" setup>
import type { UspRecord, UspScrollSectionRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage } from "vue-datocms";

const { data } = defineProps<{ data: UspScrollSectionRecord }>();

const activeUsp = ref<UspRecord>(data.sellingPoints?.at(0) ?? {} as UspRecord);

function updateActiveUsp(activeIndex: number) {
  activeUsp.value = data.sellingPoints?.at(activeIndex) ?? {} as UspRecord;
}

useSwiper();
</script>

<template>
  <div class="py-3xl bg-primary text-white">
    <FadeInSection>
      <UContainer>
        <SectionTitle as="h2" :label="data.sectionTitle" seo-fallback-label="Unique selling points" class="mb-lg" />
        <h3 class="text-4xl uppercase text-inherit [&_p]:mb-0" v-html="data.title" />

        <div class="flex gap-[116px]">
          <div class="flex flex-1 flex-col">
            <!-- mobile: content+image -->
            <ul class="gap-4xl mb-[72px] flex flex-col md:hidden">
              <li v-for="usp in data.sellingPoints" :key="usp.id" class="border-t-gradient-white pt-lg flex flex-col gap-[36px]">
                <span class="text-lg uppercase text-inherit [&_p]:mb-0" v-html="usp.title" />
                <span>{{ usp.text }}</span>
                <DatocmsImage
                  v-if="usp.image"
                  :data="usp.image.responsiveImage"
                  object-fit="cover"
                  class="!w-full sm:!w-1/2"
                />
              </li>
            </ul>

            <!-- desktop: content -->
            <div class="hidden md:block">
              <div class="border-t-gradient-white pt-lg mb-xl mt-[114px]">
                <span class="text-lg uppercase text-inherit" v-html="activeUsp.title" />
                <span>{{ activeUsp.text }}</span>
              </div>
            </div>

            <!-- both: cta -->
            <div class="mt-auto">
              <div class="mb-sm">
                {{ data.callToActionText }}
              </div>
              <UButton color="white" variant="ghost" icon="i-ph-arrow-right" trailing class="border-b-gradient-white" :link="data.callToActionLink">
                {{ data.callToActionLink.text }}
              </UButton>
            </div>
          </div>

          <!-- desktop: image -->
          <div class="relative hidden h-[670px] flex-1 md:block">
            <!-- <div class="gap-xl absolute inset-0 right-[-17px] flex flex-col overflow-y-scroll pr-[17px]">
            <div v-for="usp in data.sellingPoints" :key="usp.id">
              <DatocmsImage
                :data="usp.image!.responsiveImage"
                class="!max-w-full"
              />
            </div>
          </div> -->

            <ClientOnly>
              <SwiperCarousel
                v-slot="{ item: usp }: { item: UspRecord }"
                :items="data.sellingPoints"
                :navigation="true"
                direction="vertical"
                @slide-change="updateActiveUsp"
              >
                <DatocmsImage
                  :data="usp.image!.responsiveImage"
                  class="!max-w-full"
                  object-fit="contain"
                />
              </SwiperCarousel>
            </ClientOnly>
          </div>
        </div>
      </UContainer>
    </FadeInSection>
  </div>
</template>
